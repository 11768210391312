export default [
  {
    header: 'การจัดการ',
    icon: 'PackageIcon',
    children: [
      {
        title: 'แดชบอร์ด',
        route: 'Dashboard',
        icon: 'ClipboardIcon',
      },
      {
        title: 'จัดการผู้ใช้งาน',
        route: 'Account',
        icon: 'UserIcon',
      },
      {
        title: 'จัดการธุรกรรม',
        route: 'Transaction',
        icon: 'DollarSignIcon',
      },
      {
        title: 'จัดการแอดมิน',
        route: 'Admin',
        icon: 'ClipboardIcon',
      },
      {
        title: 'จัดการเอเจนต์',
        route: 'Agent',
        icon: 'CopyIcon',
      },
    ],
  },
]
